import { useRef, useState, useEffect, ReactNode, FC } from 'react';

import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';

interface FilterTitleProps {
    title: ReactNode;
    truncated?: boolean;
}

const FilterTitle: FC<FilterTitleProps> = ({ title, truncated = false }) => {
    const spanRef = useRef<HTMLSpanElement>(null);
    const [useHoverTip, setUseHoverTip] = useState(false);

    useEffect(() => {
        if (!spanRef.current) {
            return;
        }
        const { scrollWidth, offsetWidth } = spanRef.current;
        setUseHoverTip(scrollWidth > offsetWidth);
    }, []);

    if (!truncated) {
        return <span data-qa="serp__novafilter-title">{title}</span>;
    }

    const filterContent = (
        <span ref={spanRef} data-qa="serp__novafilter-title" className="novafilters-truncated-title">
            {title}
        </span>
    );

    return !useHoverTip ? (
        filterContent
    ) : (
        <HoverTip
            host={!process.env.SSR ? document.body : null}
            placement={TipPlacement.Top}
            layer={TipLayer.Overlay}
            render={() => title}
            activatorRef={spanRef}
        >
            {filterContent}
        </HoverTip>
    );
};

export default FilterTitle;
