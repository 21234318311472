import { ReactNode, useMemo } from 'react';

import { Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { formatFromTo } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { NovaFilterGroup, NovaFilterGroupMap, NovaFilterRangeConvertedToStrings, Range } from 'src/models/novaFilters';
import { NARROW_NON_BREAKING_SPACE, REGULAR_SPACE } from 'src/utils/constants/symbols';

import Bar, { NovaFiltersChartItem } from 'src/components/NovaFilters/components/NovaFilterWithChart/Bar';
import { NovaFilterRangeTrls } from 'src/components/NovaFilters/components/NovaFilterWithChart/InputsGroup';

import styles from './styles.less';

const CHART_HEIGHT = 40;
const MIN_CHART_HEIGHT = 6;
const MIN_CHART_ITEMS_COUNT = 5;
const getHeight = (n: number, min: number, max: number) => Math.round(((n - min) / (max - min)) * CHART_HEIGHT);
const getMinMax = (groups: NovaFilterGroupMap<string>) => {
    const values = Object.values(groups)
        .map((item) => item.count)
        .sort((a, b) => a - b);
    const min = Math.log2(values[0]);
    const max = Math.log2(values[values.length - 1]);
    return { min, max };
};

type GetTitleFunction = (v: NovaFilterGroup<string>) => ReactNode;

interface NovaFiltersChartProps {
    groups: NovaFilterGroupMap<string>;
    selectedValues: NovaFilterRangeConvertedToStrings;
    onClick: (data: NovaFiltersChartItem) => void;
    trlsTitles: NovaFilterRangeTrls;
    unitTitle: ReactNode;
    emptyMessage: string;
}

const TrlKeys = {
    empty: 'novaFiltersChart.empty',
};

const checkSelectedRangeValue = (value: number | undefined, selectedValue: string) =>
    value === (Number(selectedValue) || undefined);

export const getChartGroups = (
    groups: NovaFilterGroupMap<string>,
    selectedValues: NovaFilterRangeConvertedToStrings,
    getTitle: GetTitleFunction
): NovaFiltersChartItem[] => {
    const { min, max } = getMinMax(groups);
    return Object.entries(groups).map(([key, { count, from, to, id }]) => {
        const height = getHeight(Math.log2(count), min, max);
        return {
            height: Math.max(height, MIN_CHART_HEIGHT),
            title: getTitle(groups[key]),
            count,
            from,
            to,
            id,
            showHover: height !== 0,
            isSelected:
                checkSelectedRangeValue(from, selectedValues[Range.From]) &&
                checkSelectedRangeValue(to, selectedValues[Range.To]),
        };
    });
};

const Chart: TranslatedComponent<NovaFiltersChartProps> = ({
    trls,
    groups,
    selectedValues,
    onClick,
    trlsTitles,
    unitTitle,
    emptyMessage,
}) => {
    const { isMobile } = useBreakpoint();

    const items: NovaFiltersChartItem[] = useMemo(() => {
        const getTitle: GetTitleFunction = ({ from, to, count }) => {
            const title = formatFromTo(
                { from, to },
                { trlFrom: trlsTitles?.from, trlTo: trlsTitles?.to, trlFromTo: trlsTitles?.fromTo }
            );
            if (!title) {
                return null;
            }
            const formattedCount = numberFormatter.format(count.toString(), {
                groupSeparator: NARROW_NON_BREAKING_SPACE,
            });
            return (
                <>
                    {`${title} `}
                    {unitTitle}
                    {` — ${formattedCount} ${trlsTitles?.resumes}`}
                </>
            );
        };
        return getChartGroups(groups, selectedValues, getTitle);
    }, [groups, selectedValues, trlsTitles.from, trlsTitles.fromTo, trlsTitles.resumes, trlsTitles.to, unitTitle]);

    if (items.length < MIN_CHART_ITEMS_COUNT) {
        return (
            <div className={styles.chartEmpty}>
                <Text style="tertiary" typography="label-4-regular">
                    {trls[TrlKeys.empty]}
                    {emptyMessage}
                </Text>
            </div>
        );
    }

    const selectedGroup = items.find((item) => item.isSelected);

    return (
        <>
            <div className={styles.chart}>
                {items.map((item) => (
                    <Bar key={item.id} {...item} onClick={onClick} />
                ))}
            </div>
            {selectedGroup && isMobile && (
                <>
                    <VSpacing default={12} />
                    <Text typography="label-2-regular" style="secondary">
                        {numberFormatter.format(selectedGroup.count.toString(), {
                            groupSeparator: NARROW_NON_BREAKING_SPACE,
                        })}
                        {REGULAR_SPACE}
                        {trlsTitles?.resumes}
                    </Text>
                </>
            )}
        </>
    );
};

export default translation(Chart);
