import { ReactNode } from 'react';

import { Link, Loader } from '@hh.ru/magritte-ui';
import { DataProvider } from 'bloko/blocks/suggest/types';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterList from 'src/components/NovaFilters/components/FilterList';
import LinkMore from 'src/components/NovaFilters/components/LinkMore';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import NovaFiltersItem from 'src/components/NovaFilters/components/NovaFiltersItem';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import useNovaFiltersGroups from 'src/components/NovaFilters/hooks/useNovaFiltersGroups';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import AdditionalList, {
    ItemMap,
} from 'src/components/NovaFilters/components/NovaFilterWithAdditionalList/AdditionalList';
import MobileList from 'src/components/NovaFilters/components/NovaFilterWithAdditionalList/MobileList';
import { useAdditionalList } from 'src/components/NovaFilters/components/NovaFilterWithAdditionalList/useAdditionalList';

const TrlKeys = {
    hide: 'novafilters.additionalList.hide',
    show: 'novafilters.additionalList.show',
};

interface NovaFilterWithAdditionalListProps {
    filterName: typeof NovaFilterKey.Area | typeof NovaFilterKey.District;
    dataProvider: DataProvider;
    title: ReactNode;
    placeholder: string;
    placeholderMobile?: string;
    add: string;
}

const NovaFilterWithAdditionalList: TranslatedComponent<NovaFilterWithAdditionalListProps> = ({
    filterName,
    dataProvider,
    title,
    placeholder,
    add,
    trls,
}) => {
    const filterUpdate = useNovaFilterUpdate();
    const orders = useSelector((state) => state.searchClustersOrder?.[filterName]) || [];
    const { groups, selectedValues } = useSelector(({ searchClusters }) => searchClusters?.[filterName]);

    const { isLoading, list, showAdditionalList, toggleShowAdditionalList } = useAdditionalList(filterName);

    const { items, breakpoint } = useNovaFiltersGroups(groups, selectedValues.length, orders, false);

    const onChange = (selected: string) => {
        const newSelected = Number(selected);
        const index = selectedValues.indexOf(newSelected);
        const newSelectedValues = [...selectedValues];
        if (index === -1) {
            newSelectedValues.push(newSelected);
        } else {
            newSelectedValues.splice(index, 1);
        }
        filterUpdate(newSelectedValues, filterName);
    };

    return (
        <NovaControl
            mobileView={<MobileList filterName={filterName} dataProvider={dataProvider} title={title} add={add} />}
        >
            <NovaFilterWrapper title={title}>
                {showAdditionalList ? (
                    <AdditionalList
                        // showAdditionalList can be true only when list are already loaded
                        allItems={list as ItemMap}
                        selectedItems={selectedValues}
                        onChange={onChange}
                        placeholder={placeholder}
                        dataProvider={dataProvider}
                        filterName={filterName}
                    />
                ) : (
                    <FilterList>
                        {items.map(
                            (item, index) =>
                                index < breakpoint && (
                                    <NovaFiltersItem
                                        key={item.id}
                                        item={item}
                                        name={filterName}
                                        onChange={onChange}
                                        checked={selectedValues.includes(Number(item.id))}
                                    />
                                )
                        )}
                    </FilterList>
                )}
                {Object.keys(groups).length > 0 && (
                    <LinkMore expanded={showAdditionalList}>
                        <Link Element="button" onClick={toggleShowAdditionalList} inline>
                            {trls[showAdditionalList ? TrlKeys.hide : TrlKeys.show]}
                        </Link>
                        {NON_BREAKING_SPACE}
                        {isLoading && <Loader />}
                    </LinkMore>
                )}
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(NovaFilterWithAdditionalList);
