import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Currency from 'src/components/Currency';
import CurrencySelector from 'src/components/NovaFilters/components/CurrencySelector';
import NovaFilterWithChart from 'src/components/NovaFilters/components/NovaFilterWithChart';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    from: 'resumesearch.clusters.compensation.from',
    to: 'resumesearch.clusters.compensation.to',
    labelFrom: 'employer.resumesSearch.salary.from',
    labelTo: 'employer.resumesSearch.salary.to',
    fromTo: 'resumesearch.clusters.compensation.fromto',
    onlyWithSalary: 'employer.resumesSearch.onlyWithSalary',
    salary: 'searchvacancy.clusters.compensation',
    resumes: 'employer.resumesSearch.resumes',
    emptyMessage: 'novaFilters.salary.empty',
};

interface Props {
    name: typeof NovaFilterKey.Salary;
}

/**
 * Блок фильтра по доходу на поиске резюме.
 */
const Salary: TranslatedComponent<Props> = ({ trls, name }) => {
    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.Salary].groups);
    const currency = useSelector((state) => state.criteriaCurrencyCode);
    const isZP = useIsZarplataPlatform();

    return (
        <fieldset className="novafilter-resume-salary">
            <NovaFilterWithChart
                groups={groups}
                unitTitle={<Currency value={currency} />}
                title={
                    isZP ? (
                        <Currency prefix={`${trls[TrlKeys.salary]},${NON_BREAKING_SPACE}`} value={currency} />
                    ) : (
                        trls[TrlKeys.salary]
                    )
                }
                titleSideElement={isZP ? null : <CurrencySelector parentFilterKey={NovaFilterKey.Salary} />}
                name={name}
                trls={{
                    from: trls[TrlKeys.from],
                    to: trls[TrlKeys.to],
                    fromTo: trls[TrlKeys.fromTo],
                    labelFrom: trls[TrlKeys.labelFrom],
                    labelTo: trls[TrlKeys.labelTo],
                    onlyWithTitle: trls[TrlKeys.onlyWithSalary],
                    resumes: trls[TrlKeys.resumes],
                }}
                emptyMessage={trls[TrlKeys.emptyMessage]}
            />
        </fieldset>
    );
};

export default translation(Salary);
