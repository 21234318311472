import { FC } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';

import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterItemWrapper from 'src/components/NovaFilters/components/NovaFilterItemWrapper';
import NovaSwitchItem from 'src/components/NovaFilters/components/NovaSwitchItem';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const AcceptTemporary: FC = () => {
    // callbacks
    const sendCountsRequest = useDebouncedCountsRequest();
    const filterUpdate = useNovaFilterUpdate();
    const onChangeAcceptTemporary = (newValue: boolean) => {
        filterUpdate(newValue, NovaFilterKey.AcceptTemporary);
    };

    // single filter group
    const name = NovaFilterKey.AcceptTemporary;
    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.AcceptTemporary]?.groups);
    const items = Object.values(groups ?? {});
    const group = items.length > 0 ? items.pop() : false;
    const value = !!useSelector((state) => state.searchClusters[NovaFilterKey.AcceptTemporary]?.selectedValues);

    // can be empty
    if (!group) {
        return null;
    }

    return (
        <NovaControl
            mobileView={
                <NovaSwitchItem
                    title={group.title}
                    checked={value}
                    onClick={() => {
                        onChangeAcceptTemporary(!value);
                        sendCountsRequest();
                    }}
                    dataQa={`serp__novafilter-${name}-${group.id}`}
                />
            }
        >
            <NovaFilterItemWrapper
                left={
                    <Checkbox
                        name={name}
                        value={group.id}
                        dataQaCheckbox={`serp__novafilter-${name}-${group.id}`}
                        onChange={() => onChangeAcceptTemporary(!value)}
                        checked={value}
                    />
                }
                title={group.title}
                disabled={group.disabled}
                count={group.count}
            />
        </NovaControl>
    );
};

export default AcceptTemporary;
