import { useCallback, useMemo, useState } from 'react';

import { Checkbox, fromTree, Link } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SearchType from 'src/components/NovaFilters/SearchType';
import FilterList from 'src/components/NovaFilters/components/FilterList';
import LinkMore from 'src/components/NovaFilters/components/LinkMore';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterItemWrapper from 'src/components/NovaFilters/components/NovaFilterItemWrapper';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import FilterTreeSelector from 'src/components/NovaFilters/components/TreeSelector';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import useNovaFiltersGroups from 'src/components/NovaFilters/hooks/useNovaFiltersGroups';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useIsEmployerVacancySearch from 'src/models/employerVacancySearch/useIsEmployerVacancySearch';
import { NovaFilterGroup, NovaFilterKey, OTHER_ROLES_ID } from 'src/models/novaFilters';

import VacancyMobileFilter from 'src/components/NovaFilters/ProfessionalRole/VacancyMobile';

const TrlKeys = {
    title: 'search.filters.professional_role',
    selectMoreTrigger: 'clusters.chooseMore',
};

const sortOtherComparator = (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>): number => {
    if (a.id === OTHER_ROLES_ID) {
        return 1;
    }
    if (b.id === OTHER_ROLES_ID) {
        return -1;
    }
    return 0;
};

const ProfessionalRole: TranslatedComponent = ({ trls }) => {
    const isEmployerVacancySearch = useIsEmployerVacancySearch();

    const filterUpdateGlobal = useNovaFilterUpdate();
    const filterUpdate = useCallback(
        (values: string[]) => filterUpdateGlobal(values, NovaFilterKey.ProfessionalRole),
        [filterUpdateGlobal]
    );
    const { groups, selectedValues } = useSelector((state) => {
        const { groups, selectedValues } = state.searchClusters[NovaFilterKey.ProfessionalRole];
        return { groups, selectedValues: selectedValues?.map((item) => `${item}`) || [] };
    });
    const orders = useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.ProfessionalRole]) || [];
    const { items, breakpoint } = useNovaFiltersGroups(groups, selectedValues.length, orders);

    const filteredItems = useMemo(() => {
        const filteredItems = items.slice(0, breakpoint);
        if (!selectedValues.includes(OTHER_ROLES_ID)) {
            filteredItems.sort(sortOtherComparator);
        }
        return filteredItems;
    }, [breakpoint, selectedValues, items]);

    const handleChangeFilter = useCallback(
        (item: string) => {
            const newRoles = selectedValues.includes(item)
                ? selectedValues.filter((value) => value !== item)
                : [...selectedValues, item];

            filterUpdate(newRoles);
        },
        [filterUpdate, selectedValues]
    );

    const treeItems = useSelector((state) => state.professionalRoleTree.items);
    const collection = useMemo(() => fromTree(treeItems), [treeItems]);
    const [isVisible, setVisible] = useState(false);

    const isVacancySearch = useSelector((state) => state.searchClustersSettings.type) !== SearchType.Resume;

    return (
        <NovaControl
            mobileView={
                isVacancySearch ? (
                    <VacancyMobileFilter title={trls[TrlKeys.title]} initialValues={selectedValues} />
                ) : undefined
            }
        >
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <FilterList>
                    {filteredItems.map(({ title, id }) => {
                        const group = groups[id];
                        return (
                            <NovaFilterItemWrapper
                                key={id}
                                left={
                                    <Checkbox
                                        value={id}
                                        onChange={({ target }) => handleChangeFilter(target.value)}
                                        checked={selectedValues.includes(id)}
                                        disabled={!group}
                                    />
                                }
                                title={title}
                                count={group.count}
                                disabled={!group}
                            />
                        );
                    })}
                </FilterList>
                {!isEmployerVacancySearch && (
                    <>
                        <LinkMore onClick={() => setVisible(true)}>
                            <Link>{trls[TrlKeys.selectMoreTrigger]}</Link>
                        </LinkMore>
                        <FilterTreeSelector
                            title={trls[TrlKeys.title]}
                            isVisible={isVisible}
                            setVisible={setVisible}
                            collection={collection}
                            filterUpdate={filterUpdate}
                            selectedValues={selectedValues}
                        />
                    </>
                )}
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(ProfessionalRole);
