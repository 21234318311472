import { useCallback, useEffect, useRef, KeyboardEvent } from 'react';

import { Radio, HSpacing, NumberInput } from '@hh.ru/magritte-ui';
import { KeyCode } from 'bloko/common/constants/keyboard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberValidator, { NumberValidatorError } from 'bloko/common/numberValidator';

import ListItem from 'src/components/NovaFilters/components/ListItem';
import NovaFilterItemWrapper from 'src/components/NovaFilters/components/NovaFilterItemWrapper';
import translation from 'src/components/translation';
import { NovaFilterKey } from 'src/models/novaFilters';

import styles from './styles.less';

const TrlKeys = {
    labelFrom: 'employer.resumesSearch.salary.from',
    customSalary: 'vacancySearch.compensation.custom',
    maxExceeded: 'novafilters.maxExceeded',
    [NumberValidatorError.DecimalLength]: 'formatted.numeric.input.error.decimalLength',
    [NumberValidatorError.NotNumber]: 'formatted.numeric.input.error.notNumber',
};

interface Props {
    isInputEnabled: boolean;
    currentValueFrom: string;
    setCurrentValueFrom: (newValue: string) => void;
    setInputEnabled: (isEnabled: boolean) => void;
    updateFilters: (newValue?: string, withoutFormSending?: boolean) => void;
}

const CustomCompensation: TranslatedComponent<Props> = ({
    trls,
    currentValueFrom,
    setCurrentValueFrom,
    isInputEnabled,
    setInputEnabled,
    updateFilters,
}) => {
    const needSendRequest = useRef(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputBlockRef = useRef<HTMLDivElement>(null);
    const onKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === KeyCode.Enter) {
            updateFilters();
            needSendRequest.current = false;
        }
    };

    useEffect(() => {
        if (isInputEnabled) {
            inputRef.current?.focus();
        }
    }, [isInputEnabled]);

    useEffect(() => {
        if (!inputBlockRef.current) {
            return;
        }
        // В Firefox задизейбленный инпут не кидает клик, и он не прокидывается выше. Из-за чего не срабатывал onClick
        // Добавляю хак с pointerEvents: none, чтобы клик приходил на div
        inputBlockRef.current.style.pointerEvents = 'none';
    }, []);

    const activateCustomCompensation = () => {
        if (!inputBlockRef.current) {
            return;
        }

        inputBlockRef.current.style.pointerEvents = 'auto';
        setInputEnabled(true);
    };

    const handleInputChange = useCallback(
        (value: string) => {
            const [error] = NumberValidator.validate(value, {
                groupSeparator: '',
                decimalLength: 0,
            });

            if (!error) {
                setCurrentValueFrom(value);
                updateFilters(value, true);
            }
        },
        [setCurrentValueFrom, updateFilters]
    );

    return (
        <>
            <NovaFilterItemWrapper
                left={<Radio onChange={activateCustomCompensation} checked={isInputEnabled} />}
                title={trls[TrlKeys.customSalary]}
            />
            <ListItem>
                <div className={styles.customSalary}>
                    <HSpacing default={36} />
                    <div className={styles.input} onClick={activateCustomCompensation}>
                        <div ref={inputBlockRef}>
                            <NumberInput
                                name={NovaFilterKey.Salary}
                                ref={inputRef}
                                value={currentValueFrom}
                                placeholder={trls[TrlKeys.labelFrom]}
                                onChange={handleInputChange}
                                data-qa="novafilters-custom-compensation"
                                disabled={!isInputEnabled}
                                onBlur={() => {
                                    if (needSendRequest.current) {
                                        updateFilters();
                                    } else {
                                        needSendRequest.current = true;
                                    }
                                }}
                                onKeyDown={onKeyDown}
                            />
                        </div>
                    </div>
                </div>
            </ListItem>
        </>
    );
};

export default translation(CustomCompensation);
