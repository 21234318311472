import { ReactNode, FC } from 'react';

import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';

type RenderPointIcon = (color: string, containerSize?: 22 | 24) => ReactNode;

export const renderPointIcon: RenderPointIcon = (color, containerSize: 22 | 24 = 24) => {
    return (
        <svg
            width={containerSize}
            height={containerSize}
            viewBox={`0 0 ${containerSize} ${containerSize}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx={containerSize / 2} cy={containerSize / 2} r={5} fill={color} />
        </svg>
    );
};

interface MetroPointProps {
    name: string;
    color: string;
}

const MetroPoint: FC<MetroPointProps> = ({ name, color }) => (
    <span>
        {color && <span className="novafilters-metro-icon">{renderPointIcon(color)}</span>}
        <FilterTitle title={name} truncated />
    </span>
);

export default MetroPoint;
