import { FC, useState } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';

import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterItemWrapper from 'src/components/NovaFilters/components/NovaFilterItemWrapper';
import NovaSwitchItem from 'src/components/NovaFilters/components/NovaSwitchItem';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { LabelOption } from 'src/models/vacancySearchCriteria';

const Internship: FC = () => {
    // single filter group
    const name = LabelOption.Internship;
    const group = useSelector((state) => state.searchClusters[NovaFilterKey.Label]?.groups.internship);
    const selectedLabels = useSelector((state) => state.searchClusters[NovaFilterKey.Label]?.selectedValues);
    const [value, setValue] = useState(!!selectedLabels?.includes(LabelOption.Internship));

    // callbacks
    const sendCountsRequest = useDebouncedCountsRequest();
    const filterUpdate = useNovaFilterUpdate();
    const onChangeInternship = (intershipChecked: boolean) => {
        const newSelectedLabels = intershipChecked
            ? [...selectedLabels, LabelOption.Internship]
            : selectedLabels.filter((label) => label !== LabelOption.Internship);

        filterUpdate(newSelectedLabels, NovaFilterKey.Label);
        setValue(intershipChecked);
    };

    // can be empty
    if (!group) {
        return null;
    }

    return (
        <NovaControl
            mobileView={
                <NovaSwitchItem
                    title={group.title}
                    checked={value}
                    onClick={() => {
                        onChangeInternship(!value);
                        sendCountsRequest();
                    }}
                    dataQa={`serp__novafilter-${name}-${group.id}`}
                />
            }
        >
            <NovaFilterItemWrapper
                left={
                    <Checkbox
                        name={name}
                        value={group.id}
                        dataQaCheckbox={`serp__novafilter-${name}-${group.id}`}
                        onChange={() => onChangeInternship(!value)}
                        checked={value}
                    />
                }
                title={group.title}
                disabled={group.disabled}
                count={group.count}
            />
        </NovaControl>
    );
};

export default Internship;
