import { FormLabel, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaChipsSelect, { ChipsSelectType } from 'src/components/NovaFilters/components/NovaChipsSelect';
import useNovaSortUpdate from 'src/components/NovaFilters/hooks/useNovaSortUpdate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { OrderByOption } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    sortTitle: 'search.vacancy.clusters.sort.title',
    [OrderByOption.PublicationTime]: 'vacancySearch.sortCode.publication_time',
    [OrderByOption.SalaryDesc]: 'vacancySearch.sortCode.salary_desc',
    [OrderByOption.SalaryAsc]: 'vacancySearch.sortCode.salary_asc',
    [OrderByOption.Relevance]: 'vacancySearch.sortCode.relevance',
};

const NovaSearchOrder: TranslatedComponent = ({ trls }) => {
    const value = useSelector((state) => state.novaSorts.orderBy);
    const options = useSelector((state) => state.vacancySearchDictionaries.orderBy);
    const handleChange = useNovaSortUpdate();

    if (!options) {
        return null;
    }

    return (
        <>
            <FormLabel>{trls[TrlKeys.sortTitle]}</FormLabel>
            <VSpacing default={12} />
            <NovaChipsSelect
                name="order_by"
                selected={[value || '']}
                data-qa="serp-settings__order-by-menu"
                options={options.map((option) => ({
                    id: option,
                    title: trls[TrlKeys[option]],
                }))}
                selectType={ChipsSelectType.Radio}
                onChange={(values) => {
                    handleChange('orderBy', values[0] as OrderByOption);
                }}
            />
            <VSpacing default={24} />
        </>
    );
};

export default translation(NovaSearchOrder);
