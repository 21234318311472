import { useEffect, useState } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterList from 'src/components/NovaFilters/components/FilterList';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import NovaFiltersItem from 'src/components/NovaFilters/components/NovaFiltersItem';
import NovaMobileFilter from 'src/components/NovaFilters/components/NovaMobileFilter';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import useNovaFiltersGroups from 'src/components/NovaFilters/hooks/useNovaFiltersGroups';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterGroup, NovaFilterKey } from 'src/models/novaFilters';
import { PartTimeOption } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    title: 'cluster.partTime',
};

const emptySelectedValues: PartTimeOption[] = [];

const PartTime: TranslatedComponent = ({ trls }) => {
    // global data
    const filterUpdate = useNovaFilterUpdate();
    const orders = useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.PartTime]) || [];
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[NovaFilterKey.PartTime]);
    // local state
    const selected = selectedValues || emptySelectedValues;
    const [values, setValues] = useState<PartTimeOption[]>(selected);
    const { items } = useNovaFiltersGroups(groups, selected.length, orders, false);

    useEffect(() => {
        setValues(selected);
    }, [selected, setValues]);

    const onChange = (item: NovaFilterGroup<PartTimeOption>) => {
        const id = item.id;
        const index = values.indexOf(id);
        const newValues = [...values];
        if (index === -1) {
            newValues.push(id);
        } else {
            newValues.splice(index, 1);
        }
        setValues(newValues);
        filterUpdate(newValues, NovaFilterKey.PartTime);
    };

    return (
        <NovaControl mobileView={<NovaMobileFilter title={trls[TrlKeys.title]} name={NovaFilterKey.PartTime} />}>
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <FilterList>
                    {items.map((item) => {
                        return (
                            <NovaFiltersItem
                                key={item.id}
                                item={item}
                                name={NovaFilterKey.PartTime}
                                onChange={() => onChange(item)}
                                checked={values.includes(item.id)}
                            />
                        );
                    })}
                </FilterList>
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(PartTime);
