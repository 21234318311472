import React, { useMemo, useState } from 'react';

import { Input, VSpacing, Select, SelectOption, fromTree } from '@hh.ru/magritte-ui';
import { BarsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import getConversionType from 'bloko/common/getConversionType';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import FilterTreeSelector from 'src/components/NovaFilters/components/TreeSelector';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { CriteriaKey, FilterExpPeriodOption } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    title: 'employer.resumesSearch.expIndustryWithPeriod.title',
    select: {
        one: 'employer.resumesSearch.expIndustry.select.one',
        some: 'employer.resumesSearch.expIndustry.select.some',
        many: 'employer.resumesSearch.expIndustry.select.many',
        all: 'employer.resumesSearch.expIndustry.select.all',
    },
    modal: {
        title: 'employer.resumesSearch.expIndustry.modal.title',
        save: 'treeselector.save',
        cancel: 'treeselector.cancel',
        placeholder: 'treeselector.quicksearch',
        notFound: 'treeselector.notFound',
    },
};

const immutableInputChange = () => undefined;

const ExpIndustryWithPeriod: TranslatedComponent = ({ trls }) => {
    const [isVisible, setVisible] = useState<boolean>(false);
    const filterUpdate = useNovaFilterUpdate();

    const selectedExpIndustries = useSelector(
        (state) => state.searchClusters?.[NovaFilterKey.FilterExpIndustry]?.selectedValues || []
    );
    const { selectedValues: selectedExpPeriod, groups } = useSelector(
        (state) => state.searchClusters?.[NovaFilterKey.FilterExpPeriodForIndustry]
    );

    const treeItems = useSelector((state) => state.industriesTree);
    const collection = useMemo(() => fromTree(treeItems), [treeItems]);

    const selectedValuesTrl = useMemo(() => {
        if (selectedExpIndustries.length === 0) {
            return trls[TrlKeys.select.all];
        }
        const conversionTrl = getConversionType({
            one: trls[TrlKeys.select.one],
            some: trls[TrlKeys.select.some],
            many: trls[TrlKeys.select.many],
            value: selectedExpIndustries.length,
        });
        return format(conversionTrl, { '{0}': selectedExpIndustries.length });
    }, [trls, selectedExpIndustries]);

    const selectOptions: SelectOption<FilterExpPeriodOption>[] = useMemo(
        () => Object.values(groups).map((item) => ({ value: item.id, label: item.title })),
        [groups]
    );

    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            <Input
                buttonIcon={BarsOutlinedSize24}
                onButtonClick={() => setVisible(true)}
                onFocus={() => setVisible(true)}
                value={selectedValuesTrl}
                onChange={immutableInputChange}
                data-qa="serp__novafilter-exp-industry-select"
            />
            <FilterTreeSelector
                title={trls[TrlKeys.modal.title]}
                isVisible={isVisible}
                setVisible={setVisible}
                collection={collection}
                selectedValues={selectedExpIndustries}
                filterUpdate={(values) => filterUpdate(values, NovaFilterKey.FilterExpIndustry)}
            />
            <VSpacing default={12} />
            {groups && (
                <Select
                    type="radio"
                    name={CriteriaKey.FilterExpPeriodForIndustry}
                    triggerProps={{
                        size: 'medium',
                        label: trls[TrlKeys.title],
                        stretched: true,
                    }}
                    options={selectOptions}
                    value={selectedExpPeriod}
                    onChange={(newValue) =>
                        filterUpdate(newValue as FilterExpPeriodOption, NovaFilterKey.FilterExpPeriodForIndustry)
                    }
                    bottomSheetHeight="content"
                />
            )}
        </NovaFilterWrapper>
    );
};

export default translation(ExpIndustryWithPeriod);
