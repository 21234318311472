import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import { NovaFilterProps } from 'src/components/NovaFilters/components/NovaFilter';
import NovaFilterContent from 'src/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import NovaMobileFilter from 'src/components/NovaFilters/components/NovaMobileFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

import AcceptTemporary from 'src/components/NovaFilters/vacancies/AcceptTemporary';
import Internship from 'src/components/NovaFilters/vacancies/Internship';

import styles from './employment.less';

const TrlKeys = {
    title: 'searchvacancy.clusters.employment',
};

const Employment: TranslatedComponent<NovaFilterProps> = ({ trls, name, ...props }) => {
    const acceptTemporary = useSelector((state) => state.searchClusters[NovaFilterKey.AcceptTemporary]);
    const internship = useSelector((state) => state.searchClusters[NovaFilterKey.Label]?.groups.internship);
    const isAdditionalFiltersVisible = Boolean(acceptTemporary) || Boolean(internship);

    return (
        <>
            <NovaControl
                mobileView={
                    <NovaMobileFilter
                        {...props}
                        name={name}
                        title={trls[TrlKeys.title]}
                        withDivider={Boolean(acceptTemporary)}
                    />
                }
            >
                <NovaFilterWrapper title={trls[TrlKeys.title]} withDivider={isAdditionalFiltersVisible}>
                    <NovaFilterContent {...props} name={name} />
                </NovaFilterWrapper>
            </NovaControl>
            {isAdditionalFiltersVisible && (
                <div className={styles.list}>
                    <AcceptTemporary />
                    <Internship />
                </div>
            )}
        </>
    );
};

export default translation(Employment);
