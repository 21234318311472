import React, { useEffect, useState } from 'react';

import { Radio } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterItemWrapper from 'src/components/NovaFilters/components/NovaFilterItemWrapper';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    title: 'resume.relocation',
};

const Relocation: TranslatedComponent = ({ trls }) => {
    const filterUpdate = useNovaFilterUpdate();
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[NovaFilterKey.Relocation]);
    const [values, setValues] = useState(selectedValues);

    useEffect(() => {
        setValues(selectedValues);
    }, [selectedValues, setValues]);

    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            {groups &&
                Object.values(groups).map((item) => (
                    <NovaFilterItemWrapper
                        key={item.id}
                        left={
                            <Radio
                                value={item.id}
                                onChange={() => {
                                    setValues(item.id);
                                    filterUpdate(item.id, NovaFilterKey.Relocation);
                                }}
                                checked={values === item.id}
                                dataQaRadio={`serp__novafilter-${NovaFilterKey.Relocation}-${item.id}`}
                            />
                        }
                        title={item.title}
                    />
                ))}
        </NovaFilterWrapper>
    );
};

export default translation(Relocation);
