import { useState, useEffect } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import ResumeLanguageFilterWithVerification from 'src/components/ResumeLanguageFilter/ResumeLanguageFilterWithVerification';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    title: 'novafilters.language',
    addVerified: 'clusters.verifiedLanguages',
};

const Language: TranslatedComponent = ({ trls }) => {
    const filterUpdate = useNovaFilterUpdate();
    const searchClusters = useSelector(({ searchClusters }) => searchClusters);
    const selectedValues = searchClusters[NovaFilterKey.Language]?.selectedValues;

    const [selectedLanguages, setSelectedLanguages] = useState(selectedValues);

    useEffect(() => {
        setSelectedLanguages(selectedValues);
    }, [selectedValues, setSelectedLanguages]);

    const onChangeLanguages = (newLanguages: string[]) => {
        setSelectedLanguages(newLanguages);
        filterUpdate(newLanguages, NovaFilterKey.Language);
    };
    if (!selectedLanguages) {
        return null;
    }

    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            <ResumeLanguageFilterWithVerification
                value={selectedLanguages}
                onChange={onChangeLanguages}
                preTitleDataQa="resume-search"
            />
        </NovaFilterWrapper>
    );
};

export default translation(Language);
