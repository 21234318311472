import { Radio } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterItemWrapper from 'src/components/NovaFilters/components/NovaFilterItemWrapper';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import OnlyWithCheckbox from 'src/components/NovaFilters/components/OnlyWithCheckbox';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterGroup, NovaFilterGroupMap, NovaFilterKey } from 'src/models/novaFilters';
import { Gender as GenderType } from 'src/models/resume/resumeCommon.types';
import { UNKNOWN } from 'src/utils/constants/resumeGenders';

const getCount = (item: NovaFilterGroup<GenderType>, groups: NovaFilterGroupMap<GenderType>) => {
    if (item.id === UNKNOWN) {
        return Object.values(groups).reduce((sum, group) => {
            return sum + group.count;
        }, 0);
    }
    return item.count;
};

const TrlKeys = {
    title: 'clusters.gender',
    onlyWith: 'employer.resumesSearch.onlyWithGender',
};

interface Props {
    name: typeof NovaFilterKey.Gender;
}

const Gender: TranslatedComponent<Props> = ({ trls, name }) => {
    const filterUpdate = useNovaFilterUpdate();
    const { groups, selectedValues } = useSelector((state) => state.searchClusters[NovaFilterKey.Gender]);
    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            {Object.values(groups).map((item) => {
                const disabled = item.id !== UNKNOWN && item?.disabled;
                return (
                    <NovaFilterItemWrapper
                        key={item.id}
                        left={
                            <Radio
                                name={name}
                                value={item.id}
                                onChange={() => filterUpdate({ value: item.id, onlyWith: item.id !== UNKNOWN }, name)}
                                checked={selectedValues.value === item.id}
                                disabled={disabled}
                                dataQaRadio="serp__novafilter-item-text"
                            />
                        }
                        title={item.title}
                        count={getCount(item, groups)}
                        disabled={disabled}
                    />
                );
            })}
            <OnlyWithCheckbox
                values={selectedValues}
                onChange={(newValues) => filterUpdate(newValues, name)}
                name={name}
                title={trls[TrlKeys.onlyWith]}
            />
        </NovaFilterWrapper>
    );
};

export default translation(Gender);
