import { Radio } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterItemWrapper from 'src/components/NovaFilters/components/NovaFilterItemWrapper';
import translation from 'src/components/translation';
import { NovaFilterGroupMap } from 'src/models/novaFilters';

const TrlKeys = {
    doesNotMatter: 'vacancySearch.experience.doesNotMatter',
};

interface CompensationListProps {
    name: string;
    groups: NovaFilterGroupMap<string>;
    currentValueFrom: string;
    isCustomCompensation: boolean;
    onChangeListValue: (id: string, onlyWithSalary: boolean) => void;
}

const CompensationList: TranslatedComponent<CompensationListProps> = ({
    groups,
    name,
    trls,
    currentValueFrom,
    isCustomCompensation,
    onChangeListValue,
}) => {
    return (
        <>
            <NovaFilterItemWrapper
                left={
                    <Radio
                        onChange={() => onChangeListValue('', false)}
                        checked={!currentValueFrom && !isCustomCompensation}
                    />
                }
                title={trls[TrlKeys.doesNotMatter]}
            />
            {Object.values(groups).map((group) => {
                if (group.id === 'only_with_salary') {
                    return null;
                }
                return (
                    <NovaFilterItemWrapper
                        key={group.id}
                        left={
                            <Radio
                                name={name}
                                value={group.id}
                                onChange={({ target: { value } }) => onChangeListValue(value, true)}
                                checked={currentValueFrom === group.id && !isCustomCompensation}
                            />
                        }
                        title={group.title}
                        count={group.count}
                    />
                );
            })}
        </>
    );
};

export default translation(CompensationList);
