import { useCallback, useRef, useState, useEffect, useMemo, ReactNode, FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import OnlyWithCheckbox from 'src/components/NovaFilters/components/OnlyWithCheckbox';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { rangeKeys } from 'src/components/NovaFilters/novaFilterUtils';
import { useSelector } from 'src/hooks/useSelector';
import {
    NovaFilterGroupMap,
    NovaFilterKey,
    Range,
    NovaFilterRange,
    NovaFilterRangeConvertedToStrings,
} from 'src/models/novaFilters';
import { SEARCH_CLUSTERS_INITIAL_STATE } from 'src/models/searchClusters';

import NovaFiltersChart from 'src/components/NovaFilters/components/NovaFilterWithChart/Chart';
import InputsGroup, {
    NovaFilterRangeTrls,
} from 'src/components/NovaFilters/components/NovaFilterWithChart/InputsGroup';

const convertSelectedValuesToString = (
    from?: string | number | null,
    to?: string | number | null,
    onlyWith = false
): NovaFilterRangeConvertedToStrings => {
    return {
        [Range.From]: `${from || ''}`,
        [Range.To]: `${to || ''}`,
        onlyWith,
    };
};

interface NovaFilterWithChartProps {
    groups: NovaFilterGroupMap<string>;
    title: ReactNode;
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age;
    titleSideElement?: ReactNode;
    unitTitle?: ReactNode;
    emptyMessage?: string;
    trls: NovaFilterRangeTrls;
}

const NovaFilterWithChart: FC<NovaFilterWithChartProps> = ({
    groups,
    title,
    name,
    titleSideElement,
    trls,
    unitTitle = '',
    emptyMessage = '',
}) => {
    const filterUpdate = useNovaFilterUpdate();
    const { from, to, onlyWith } = useSelector((state) => state.searchClusters[name].selectedValues);
    const initialValues = useMemo(() => convertSelectedValuesToString(from, to, onlyWith), [from, to, onlyWith]);
    const lastUpdatedValue = useRef(initialValues);
    const [selectedValues, setSelectedValues] = useState(initialValues);

    useEffect(() => {
        setSelectedValues(initialValues);
    }, [initialValues, setSelectedValues]);

    const updateFilters = useCallback(
        (data?: NovaFilterRangeConvertedToStrings, withoutFormSending?: boolean) => {
            const newValues = data || selectedValues;
            if (
                rangeKeys.some((key) => lastUpdatedValue.current[key] !== newValues[key]) ||
                newValues.onlyWith !== selectedValues.onlyWith
            ) {
                filterUpdate(newValues, name, withoutFormSending);
            }
            if (!withoutFormSending) {
                lastUpdatedValue.current = newValues;
            }
        },
        [selectedValues, filterUpdate, name]
    );

    const onChange = useCallback(
        (data: NovaFilterRangeConvertedToStrings, forceUpdate: boolean | undefined) => {
            setSelectedValues(data);
            updateFilters(data, !forceUpdate);
        },
        [setSelectedValues, updateFilters]
    );
    const onClick = useCallback(
        (data: NovaFilterRange) => {
            const newData: NovaFilterRangeConvertedToStrings = {
                [Range.From]: `${data[Range.From] ?? ''}`,
                [Range.To]: `${data[Range.To] ?? ''}`,
                onlyWith: selectedValues.onlyWith,
            };
            onChange(newData, true);
        },
        [onChange, selectedValues.onlyWith]
    );

    const handleReset = useCallback(() => {
        const { from, to, onlyWith } = SEARCH_CLUSTERS_INITIAL_STATE[name].selectedValues;

        onChange(convertSelectedValuesToString(from, to, onlyWith), true);
    }, [onChange, name]);

    return (
        <NovaFilterWrapper title={title} titleSideElement={titleSideElement}>
            <NovaFiltersChart
                groups={groups}
                selectedValues={selectedValues}
                onClick={onClick}
                trlsTitles={trls}
                unitTitle={unitTitle}
                emptyMessage={emptyMessage}
            />
            <VSpacing default={12} />
            <InputsGroup
                values={selectedValues}
                onChange={onChange}
                updateFilters={updateFilters}
                name={name}
                trls={trls}
            />
            <VSpacing default={12} />
            <OnlyWithCheckbox
                values={selectedValues}
                onChange={onChange}
                name={name}
                title={trls.onlyWithTitle}
                onReset={handleReset}
            />
        </NovaFilterWrapper>
    );
};

export default NovaFilterWithChart;
