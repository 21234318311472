import { useRef, useCallback } from 'react';

import { FormLabel, VSpacing, Input } from '@hh.ru/magritte-ui';
import { KeyCode } from 'bloko/common/constants/keyboard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ListItem from 'src/components/NovaFilters/components/ListItem';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    title: 'clusters.excluded_text',
    placeholder: 'novaFilters.excludedText',
};

const ExcludedText: TranslatedComponent = ({ trls }) => {
    const needSendRequest = useRef<boolean>(true);
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const selectedValue = useSelector(
        (state) => state.searchClusters?.[NovaFilterKey.ExcludedText].selectedValues[0] || ''
    );

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            if (event.keyCode === KeyCode.Enter) {
                needSendRequest.current = false;
                filterUpdate([event.currentTarget.value], NovaFilterKey.ExcludedText);
            }
        },
        [filterUpdate]
    );

    const onChange = useCallback(
        (value: string) => {
            filterUpdate([value], NovaFilterKey.ExcludedText, true);
            sendCountsRequest();
        },
        [filterUpdate, sendCountsRequest]
    );

    return (
        <NovaControl
            mobileView={
                <>
                    <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                    <VSpacing default={12} />
                    <Input
                        clearable
                        value={selectedValue}
                        placeholder={trls[TrlKeys.placeholder]}
                        data-qa="novafilters-mobile-excluded-text-input"
                        onChange={onChange}
                    />
                    <VSpacing default={24} />
                </>
            }
        >
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <ListItem>
                    <Input
                        value={selectedValue}
                        data-qa="novafilters-excluded-text-input"
                        placeholder={trls[TrlKeys.placeholder]}
                        onBlur={({ target }) => {
                            if (needSendRequest.current) {
                                filterUpdate([target.value], NovaFilterKey.ExcludedText);
                            } else {
                                needSendRequest.current = true;
                            }
                        }}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                    />
                </ListItem>
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(ExcludedText);
