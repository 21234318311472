import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import translation from 'src/components/translation';

import NovaSearchOrder from 'src/components/NovaFilters/components/SortingGroup/NovaSearchOrder';
import NovaSearchPeriod from 'src/components/NovaFilters/components/SortingGroup/NovaSearchPeriod';

const SortingGroup: TranslatedComponent = () => {
    return (
        <NovaControl
            mobileView={
                <>
                    <NovaSearchOrder />
                    <NovaSearchPeriod />
                </>
            }
        />
    );
};

export default translation(SortingGroup);
