import { Link, VSpacing } from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSetEmployerVacancyListDefault } from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useIsEmployerVacancySearch from 'src/models/employerVacancySearch/useIsEmployerVacancySearch';

import SearchType from 'src/components/NovaFilters/SearchType';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import useResetLink from 'src/components/NovaFilters/hooks/useResetLink';

const TrlKeys = {
    reset: 'novafilters.reset',
};

const Reset: TranslatedComponent = ({ trls }) => {
    const isResumeSearch = useSelector((state) => state.searchClustersSettings.type) === SearchType.Resume;
    const isEmployerVacancySearch = useIsEmployerVacancySearch();
    const totalUsedVacancyFilters = useSelector((state) => state.vacancySearchResult.totalUsedFilters);
    const totalUsedEmployerVacancySearchFilters = useSelector((state) => state.employerVacancySearch.totalUsedFilters);
    const totalUsedResumeFilters = useSelector((state) => state.resumeSearchResult.totalUsedFilters);
    const totalUsedFilters =
        totalUsedVacancyFilters || totalUsedResumeFilters || totalUsedEmployerVacancySearchFilters || 0;
    const { setEmployerVacancyListDefault } = useSetEmployerVacancyListDefault();
    const resetLink = useResetLink();

    const linkProps = {
        style: 'neutral',
        typography: 'label-2-regular',
        disabled: !totalUsedFilters,
        children: trls[TrlKeys.reset],
    } as const;

    return (
        <NovaControl>
            {isResumeSearch && <VSpacing default={24} />}
            {isEmployerVacancySearch ? (
                <Link {...linkProps} Element="button" onClick={setEmployerVacancyListDefault} />
            ) : (
                <>
                    <Link {...linkProps} Element={SpaLink} to={resetLink} />
                    <VSpacing default={24} />
                </>
            )}
        </NovaControl>
    );
};

export default translation(Reset);
