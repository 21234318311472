import { FC, PropsWithChildren } from 'react';

import { Title } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface ListItemProps {
    header?: boolean;
}

const ListItem: FC<ListItemProps & PropsWithChildren> = ({ header, children }) => (
    <li className={styles.listItem} data-qa="serp__novafilter-item">
        {header ? (
            <Title Element="h4" size="small" style="secondary">
                {children}
            </Title>
        ) : (
            children
        )}
    </li>
);

export default ListItem;
