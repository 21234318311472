import { useCallback, useEffect, useMemo, useState } from 'react';

import debounce from 'bloko/common/debounce';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';
import { AdditionalDefault } from 'bloko/common/tree/types';

import NovaMobileFilterWithActivator from 'src/components/NovaFilters/components/NovaMobileFilterWithActivator';
import ChildrenMobileFilter, { MultiAction } from 'src/components/NovaFilters/components/NovaTree/ChildrenMobileFilter';
import ParentMobileFilter from 'src/components/NovaFilters/components/NovaTree/ParentMobileFilter';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

import SelectedRoles from 'src/components/NovaFilters/ProfessionalRole/VacancyMobile/SelectedRoles';

interface ProfessionalRolesMobilePros {
    initialValues: string[];
    title: string;
}

const TrlKeys = {
    add: 'novaFilters.professionalRoles.add',
    searchParent: 'novaFilters.professionalRoles.search.parent',
    searchChild: 'novaFilters.professionalRoles.search.child',
};

const ProfessionalRolesMobile: TranslatedComponent<ProfessionalRolesMobilePros> = ({ initialValues, title, trls }) => {
    const professionalRoleTree = useSelector(({ professionalRoleTree }) => professionalRoleTree);
    const [collection, setCollection] = useState<TreeCollection<AdditionalDefault>>(
        fromTree(professionalRoleTree.items)
    );
    useEffect(() => setCollection(fromTree(professionalRoleTree.items)), [professionalRoleTree]);

    // store's data
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState<string[]>(initialValues);

    // search text and input
    const [searchQuery, setQuery] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [selectedParent, setSelectedParent] = useState<string>('');

    // fast delete role
    const handleClear = useCallback(() => {
        setSelectedValues([]);
        filterUpdate([], NovaFilterKey.ProfessionalRole);
        sendCountsRequest();
    }, [filterUpdate, sendCountsRequest]);

    // update text (debounce for render children)
    const searchRolesDebounced = useMemo(
        () =>
            debounce((text: string) => {
                setSearchText(text);
            }, 400),
        []
    );

    // update search input
    const onChangeSearchQuery = useCallback(
        (value: string) => {
            searchRolesDebounced(value);
            setQuery(value);
        },
        [searchRolesDebounced]
    );

    // click on parent and child
    const onChangeParent = useCallback((id: string) => {
        setSelectedParent(id);
    }, []);

    // all checkbox
    const onMultiChange = useCallback(
        (action: MultiAction, changedValues: string[]) => {
            const newValues =
                action === MultiAction.Add
                    ? [...selectedValues, ...changedValues]
                    : selectedValues.filter((value) => !changedValues.includes(value));
            setSelectedValues(newValues);
            filterUpdate(newValues, NovaFilterKey.ProfessionalRole);
        },
        [filterUpdate, selectedValues]
    );

    const onChangeChild = useCallback(
        (id: string) => {
            const index = selectedValues.indexOf(id);
            const newSelectedValues = [...selectedValues];
            if (index === -1) {
                newSelectedValues.push(id);
            } else {
                newSelectedValues.splice(index, 1);
            }
            setSelectedValues(newSelectedValues);
            filterUpdate(newSelectedValues, NovaFilterKey.ProfessionalRole);
        },
        [filterUpdate, selectedValues]
    );

    const handleClose = () => {
        setShowModal(false);
        setQuery('');
        setSearchText('');
        onChangeParent('');
        sendCountsRequest();
    };

    const titleModal = trls[selectedParent ? TrlKeys.searchChild : TrlKeys.searchParent];

    // TODO: перевести на TreeSelector в https://jira.hh.ru/browse/PORTFOLIO-30300
    return (
        <NovaMobileFilterWithActivator
            title={title}
            titleModal={titleModal}
            add={trls[TrlKeys.add]}
            showModal={showModal}
            setShowModal={setShowModal}
            selected={<SelectedRoles selectedValues={selectedValues} collection={collection} onClear={handleClear} />}
            onChangeSearchQuery={onChangeSearchQuery}
            searchQuery={searchQuery}
            showBack={!!selectedParent}
            onBack={() => {
                if (selectedParent) {
                    onChangeParent('');
                    return;
                }
                handleClose();
            }}
            onClose={handleClose}
            content={
                <>
                    {!selectedParent && showModal && (
                        <ParentMobileFilter
                            collection={collection}
                            searchText={searchText}
                            selectedValues={selectedValues}
                            onChange={onChangeParent}
                        />
                    )}
                    {selectedParent && (
                        <ChildrenMobileFilter
                            collection={collection}
                            searchText={searchText}
                            parent={selectedParent}
                            selectedValues={selectedValues}
                            onChange={onChangeChild}
                            name={NovaFilterKey.ProfessionalRole}
                            onMultiChange={onMultiChange}
                        />
                    )}
                </>
            }
            activatorDataQa="novafilters-mobile-add-professional-roles"
            inputDataQa="novafilters-mobile-professional-role-input"
        />
    );
};

export default translation(ProfessionalRolesMobile);
