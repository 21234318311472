import { Fragment } from 'react';

import { Badge, HSpacing, Link } from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import NoIndex from 'src/components/NoIndex';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'resumesSearch.novaFilters.conditions',
    add: 'resumesSearch.novaFilters.add',
    edit: 'resumesSearch.novaFilters.edit',
};

const Total: TranslatedComponent = ({ trls }) => {
    const totalUsedFilters = useSelector((state) => state.resumeSearchResult.totalUsedFilters ?? 0);
    const currentParams = useSelector((state) => state.resumeSearchResult.queries?.currentParams ?? '');

    return (
        <NovaFilterWrapper
            title={
                <Fragment>
                    {trls[TrlKeys.title]}
                    {totalUsedFilters > 0 && (
                        <>
                            <HSpacing default={6} />
                            <Badge style="neutral">{`${totalUsedFilters}`}</Badge>
                        </>
                    )}
                </Fragment>
            }
        >
            <NoIndex>
                <Link
                    Element={SpaLink}
                    to={`${paths.resumeSearch}/advanced?no_default_area&${currentParams}`}
                    data-qa="resume-serp__change-request"
                    rel="nofollow"
                >
                    {totalUsedFilters > 0 ? trls[TrlKeys.edit] : trls[TrlKeys.add]}
                </Link>
            </NoIndex>
        </NovaFilterWrapper>
    );
};

export default translation(Total);
