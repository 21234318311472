import { FC, ReactElement, useRef, useState, useLayoutEffect, ReactNode } from 'react';

import { Cell, CellText, CellRightLabel, TooltipHover } from '@hh.ru/magritte-ui';

import FilterCount from 'src/components/NovaFilters/components/FilterCount';
import ListItem from 'src/components/NovaFilters/components/ListItem';

interface NovaFilterItemWrapperProps {
    left: ReactElement;
    title: ReactNode;
    count?: number;
    disabled?: boolean;
}

const NovaFilterItemWrapper: FC<NovaFilterItemWrapperProps> = ({ left, title, count, disabled = false }) => {
    const [isHoverExist, setHoverExist] = useState(false);

    const textRef = useRef<HTMLDivElement>(null);
    const activatorRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (textRef.current && textRef.current.scrollHeight > textRef.current.offsetHeight) {
            setHoverExist(true);
        }
    }, []);

    return (
        <ListItem>
            {isHoverExist && (
                <TooltipHover placement="top-center" activatorRef={activatorRef} maxWidth={300}>
                    {title}
                </TooltipHover>
            )}
            <div ref={activatorRef}>
                <Cell
                    align="top"
                    left={left}
                    right={
                        !disabled && count ? (
                            <CellRightLabel hideIcon>
                                <FilterCount count={count} />
                            </CellRightLabel>
                        ) : undefined
                    }
                    disabled={disabled}
                >
                    <CellText maxLines={3} data-qa="serp__novafilter-item-text">
                        <div ref={textRef}>{title}</div>
                    </CellText>
                </Cell>
            </div>
        </ListItem>
    );
};

export default NovaFilterItemWrapper;
