import { useMemo } from 'react';

// common filters
import ProfessionalRoles from 'src/components/NovaFilters/ProfessionalRole';
import Schedule from 'src/components/NovaFilters/Schedule';
import SearchType from 'src/components/NovaFilters/SearchType';
// filters serp resume
import Age from 'src/components/NovaFilters/resumes/Age';
import ResumeArea from 'src/components/NovaFilters/resumes/Area';
import Citizenship from 'src/components/NovaFilters/resumes/Citizenship';
import ResumeDistrict from 'src/components/NovaFilters/resumes/District';
import DriverLicenseTypes from 'src/components/NovaFilters/resumes/DriverLicenseTypes';
import EducationLevel from 'src/components/NovaFilters/resumes/EducationLevel';
import ResumeEmployment from 'src/components/NovaFilters/resumes/Employment';
import EtcFilter from 'src/components/NovaFilters/resumes/EtcFilter';
import ExpIndustryWithPeriod from 'src/components/NovaFilters/resumes/ExpIndustryWithPeriod';
import ResumeExperience from 'src/components/NovaFilters/resumes/Experience';
import Gender from 'src/components/NovaFilters/resumes/Gender';
import JobSearchStatus from 'src/components/NovaFilters/resumes/JobSearchStatus';
import ResumeLabels from 'src/components/NovaFilters/resumes/Labels';
import Language from 'src/components/NovaFilters/resumes/Language';
import ResumeMetro from 'src/components/NovaFilters/resumes/Metro';
import Relocation from 'src/components/NovaFilters/resumes/Relocation';
import Salary from 'src/components/NovaFilters/resumes/Salary';
import SimilarResume from 'src/components/NovaFilters/resumes/SimilarResume';
import Skill from 'src/components/NovaFilters/resumes/Skill';
import UniversitySuggest from 'src/components/NovaFilters/resumes/UniversitySuggest';
import ResumeVacancyId from 'src/components/NovaFilters/resumes/VacancyId';
import VerifiedSkillsFilter from 'src/components/NovaFilters/resumes/VerifiedSkillsFilter';
import WorkTicket from 'src/components/NovaFilters/resumes/WorkTicket';
// filters serp vacancy
import VacancyArea from 'src/components/NovaFilters/vacancies/Area';
import Company from 'src/components/NovaFilters/vacancies/Company';
import Compensation from 'src/components/NovaFilters/vacancies/Compensation';
import VacancyDistrict from 'src/components/NovaFilters/vacancies/District';
import Education from 'src/components/NovaFilters/vacancies/Education';
import VacancyEmployment from 'src/components/NovaFilters/vacancies/Employment';
import ExcludedText from 'src/components/NovaFilters/vacancies/ExcludedText';
import VacancyExperience from 'src/components/NovaFilters/vacancies/Experience';
import IndustryAndSubIndustry from 'src/components/NovaFilters/vacancies/IndustryAndSubIndustry';
import VacancyLabels from 'src/components/NovaFilters/vacancies/Labels';
import VacancyMetro from 'src/components/NovaFilters/vacancies/Metro';
import Neighbours from 'src/components/NovaFilters/vacancies/Neighbours';
import PartTime from 'src/components/NovaFilters/vacancies/PartTime';
import SearchField from 'src/components/NovaFilters/vacancies/SearchField';
import VacancySimilarResume from 'src/components/NovaFilters/vacancies/SimilarResume';
import VacancyId from 'src/components/NovaFilters/vacancies/VacancyId';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const COMMON_FILTERS = {
    [NovaFilterKey.ProfessionalRole]: ProfessionalRoles,
} as const;

const VACANCY_SPECIFIC_FILTERS = {
    [NovaFilterKey.Area]: VacancyArea,
    [NovaFilterKey.District]: VacancyDistrict,
    [NovaFilterKey.Compensation]: Compensation,
    [NovaFilterKey.Company]: Company,
    [NovaFilterKey.Experience]: VacancyExperience,
    [NovaFilterKey.Neighbours]: Neighbours,
    [NovaFilterKey.Metro]: VacancyMetro,
    [NovaFilterKey.Industry]: IndustryAndSubIndustry,
    [NovaFilterKey.PartTime]: PartTime,
    [NovaFilterKey.Resume]: VacancySimilarResume,
    [NovaFilterKey.VacancyId]: VacancyId,
    [NovaFilterKey.ExcludedText]: ExcludedText,
    [NovaFilterKey.Label]: VacancyLabels,
    [NovaFilterKey.Education]: Education,
    [NovaFilterKey.Employment]: VacancyEmployment,
    [NovaFilterKey.Schedule]: Schedule,
    [NovaFilterKey.SearchField]: SearchField,
} as const;

const RESUME_SPECIFIC_FILTERS = {
    [NovaFilterKey.Area]: ResumeArea,
    [NovaFilterKey.Age]: Age,
    [NovaFilterKey.District]: ResumeDistrict,
    [NovaFilterKey.Gender]: Gender,
    [NovaFilterKey.Metro]: ResumeMetro,
    [NovaFilterKey.Resume]: SimilarResume,
    [NovaFilterKey.Relocation]: Relocation,
    [NovaFilterKey.Experience]: ResumeExperience,
    [NovaFilterKey.FilterExpIndustry]: ExpIndustryWithPeriod,
    [NovaFilterKey.Salary]: Salary,
    [NovaFilterKey.Citizenship]: Citizenship,
    [NovaFilterKey.Schedule]: Schedule,
    [NovaFilterKey.Skill]: Skill,
    [NovaFilterKey.VerifiedSkills]: VerifiedSkillsFilter,
    [NovaFilterKey.Etc]: EtcFilter,
    [NovaFilterKey.JobSearchStatus]: JobSearchStatus,
    [NovaFilterKey.EducationLevel]: EducationLevel,
    [NovaFilterKey.Employment]: ResumeEmployment,
    [NovaFilterKey.Label]: ResumeLabels,
    [NovaFilterKey.University]: UniversitySuggest,
    [NovaFilterKey.VacancyId]: ResumeVacancyId,
    [NovaFilterKey.DriverLicenseTypes]: DriverLicenseTypes,
    [NovaFilterKey.WorkTicket]: WorkTicket,
    [NovaFilterKey.Language]: Language,
} as const;

type ResumeFilters = typeof COMMON_FILTERS & typeof RESUME_SPECIFIC_FILTERS;
type VacancyFilters = typeof COMMON_FILTERS & typeof VACANCY_SPECIFIC_FILTERS;

interface FiltersBySearchType {
    [SearchType.Resume]: ResumeFilters;
    [SearchType.Vacancy]: VacancyFilters;
}

const FILTERS_BY_SEARCH_TYPE: FiltersBySearchType = {
    [SearchType.Resume]: {
        ...COMMON_FILTERS,
        ...RESUME_SPECIFIC_FILTERS,
    },
    [SearchType.Vacancy]: {
        ...COMMON_FILTERS,
        ...VACANCY_SPECIFIC_FILTERS,
    },
};

const useNovaFilterMap = (): VacancyFilters | ResumeFilters => {
    const searchType = useSelector((state) =>
        state.searchClustersSettings.type === SearchType.VacancyMap ||
        state.searchClustersSettings.type === SearchType.EmployerVacancy
            ? SearchType.Vacancy
            : state.searchClustersSettings.type
    );

    return useMemo(() => FILTERS_BY_SEARCH_TYPE[searchType], [searchType]);
};

export default useNovaFilterMap;
