import { useMemo } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import formatter, { FormSuggestItem } from 'src/components/Forms/formatter';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'src/components/translation';
import useFetchAreaTree from 'src/hooks/useFetchAreaTree';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { buildDataProvider, getAutosuggestItems } from 'src/utils/suggest/buildDataProvider';

import NovaFilterWithSuggest from 'src/components/NovaFilters/components/NovaFilterWithSuggest';

interface Props {
    title: string;
    name: typeof NovaFilterKey.Citizenship | typeof NovaFilterKey.WorkTicket;
}

const TrlKeys = {
    country: 'employer.resumesSearch.region.country',
};

const AUTOSUGGEST_URL = '/autosuggest/multiprefix/v2';

const RegionSuggest: TranslatedComponent<Props> = ({ trls, title, name }) => {
    useFetchAreaTree();
    const lang = useSelector((state) => state.langs[0]);
    const dynamicUrlPart = `countries_${lang}`;
    const dataProvider = useMemo(
        () => buildDataProvider<FormSuggestItem>(AUTOSUGGEST_URL, dynamicUrlPart, formatter, getAutosuggestItems),
        [dynamicUrlPart]
    );
    const areaTree = useSelector((state) => state.areaTree);
    const areaTreeCollection = fromTree(areaTree);

    const filterUpdate = useNovaFilterUpdate();
    const selectedValues = useSelector((state) => state.searchClusters[name].selectedValues);
    const enrichmentSelectedValues: Required<FormSuggestItem>[] = useMemo(
        () =>
            selectedValues.map((id) => ({
                id,
                text: areaTreeCollection.getModel(id)?.text || '',
            })),
        [areaTreeCollection, selectedValues]
    );

    return (
        <NovaFilterWithSuggest
            title={title}
            selectedValues={enrichmentSelectedValues}
            name={name}
            filterUpdate={(values) => {
                filterUpdate(
                    values.map(({ id }) => `${id}`),
                    name
                );
            }}
            dataProvider={dataProvider}
            placeholder={trls[TrlKeys.country]}
        />
    );
};

export default translation(RegionSuggest);
